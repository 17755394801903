:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding:0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: #17347A;
    --topmenu-color: white;
    --topmenu-li-a-padding: 6px 15px;
    
        /* Typo */
    --scale-factor: 1.418;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;

}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/
p {
    margin-top:0;
}

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.blue {
    background-color:#2F7398;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid black;
}

.page {
    min-height:80vh;
}

/* header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    border-top:#00963f solid 3px;
}

header .container {
    max-width:1280px;
    margin:0 auto;
    padding:0;
}

header a {
    color:black;
}

.topout {
    border-bottom:#F5F5F5 solid 1px;
}

.topout .flex {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.topcontact {
    background:#960000;
    padding:0.5em;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.topcontact:hover {
    background:#17347A;
}

.topcontactlistbtn {
    background:#00963f;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius:5px;
    padding:0.5em;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.topcontactlistbtn:hover {
    background:#17347A;
}

.topout a {
    text-decoration:none;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.topout a:hover {
    color:white;
}

.topout2 {
    background:#eee;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
    margin-right:1px;
}

.menu li a:hover, .menu .active > a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:#fff;
    z-index:100;
}

.menu .dropdown ul.sub-menu-items li {
    display: block;
}

.menu .dropdown ul {
    left: 24px;
    border-bottom: 3px #17347a solid;
}

.leftmenu ul {
    list-style:none;
    padding:0;
}

.leftmenu li {
    display:block;
    padding:0 1em;
}

.leftmenu li a {
    display:block;
    text-decoration:none;
    padding:0.5em 0;
    border-bottom:#d8d8d8 dotted 1px;
    color:#000;
    font-weight:500;
    font-size:0.9rem;
}

.leftmenu li a:hover {
    color:#002EA0;
}

.logoimg img {
    max-width: 150px;
}

.logo {
    padding:0.3em 0;
}

.logo a {
    font-size:0;
}

.regionskane {
    padding-left: 0.5rem;
}
.regionskane img {
    max-height: 24px;
}

/* slider
---------------------------------------------------------*/

.slick-slider {
    margin:0 auto;
    position: relative;
}
.startsida .slick-track {
    height: 60vh;
}

.slick-slide {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;

} 


.slider-content .container {
    max-width:880px;
}

.slider-content span {
    display:block;
}

.slider-content span {
    display:block;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    text-align:center;
    font-weight:700;
    color:white;
}

.slider-header {
    font-size:3rem;
}

.slider-content-txt {
    font-size:1.6rem !important; 
    font-weight:normal;
    color:white;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color:#00963f;
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:#00963f;
    display:block;
}

.slick-dots li {
    border:#00963f solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}


/* Startbox
---------------------------------------------------------*/

.startbox {
    text-decoration:none;
    color:#111;
}

.startbox article {
    margin-bottom:1.5em;
}

.pop-img img {
    width:100%;
    border-bottom:#80451D solid 4px;
}

.startbox-inner {
    padding:0 1.5em;
    display:block;
    position:relative;
}

.block-content {
    color:#111;
}

.block-rub h2 {
    color:#251408;
    font-size:2.0rem;
    font-weight:bold;
}

.boxLink {
    padding-bottom:1.5em;
}

.zoombox { height:20vw; overflow:hidden; position:relative; color:#fff; text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4); margin-bottom:0;}
.zoombox a { -webkit-transition: color 3s ease-in-out 0s; transition: color 3s ease-in-out 0s;}
.zoombox a:hover { color: rgba(255, 255, 255, 1); }
.zoombox_innerdiv {height:100%; width:100%; background-position: 50% 50%; background-size:cover; transform: scale(1); position:absolute; transition: transform 3s ease-in 0s;}
.zoomboxar > a:hover .zoombox_innerdiv {transform: scale(1.2);transition: transform 3s ease-in 0s;} 
.zoombox_textdiv {height:100%; width: 100%; overflow:hidden; margin:0;  padding: 0px 2em; text-align:center; position:absolute;} 

.zoombox h1, .zoombox h2 {padding-bottom: 35px; position: relative; text-align: center; font-size:2.2rem; text-shadow:0px 0px 20px rgba(0, 0, 0, 0.4); font-weight:700; }
.zoombox:hover h1:after, .zoombox:hover h2:after {opacity:1; transition:0.5s ease-in 0s; }
.zoombox h1 span, .zoombox h2 span { display:block; font-size:1.2rem; padding-top:0.5em;}

.zoombox h1, .zoombox h2 { color: rgba(255, 255, 255, 0.4); -webkit-transition: color 1s ease-in-out 0s; transition: color 1s ease-in-out 0s; display:block;}
.zoombox h1:hover, .zoombox h2:hover { color: rgba(255, 255, 255, 1);}

/* block2
---------------------------------------------------------*/
section.start-block-wrappe2 {
    padding:1.5em 0;
    min-height:5vh;
}

.block2-content {
    text-align:center;
    max-width:850px;
    margin:0 auto;
    width:100%;
}

/* block3
---------------------------------------------------------*/
.start-block-wrapper3 {
    padding:0;
    min-height:5vh;
}

.start-block-wrapper3 .container > h2 {
    text-align:center;
}

/* block4
---------------------------------------------------------*/
.start-block-wrapper4 {
    color:white;
}

.start-block-wrapper4 h3 {
    color:white;
    font-weight:bold;
}

.start-block-wrapper4 button {
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

/* Page
---------------------------------------------------------*/
.flex.margin.layout {
    align-items: flex-start;
}

.right-inner {
    padding:1.5em;
}

.right-inner span {
    display:block;
}

.right-opentime {
    margin-top:1.5em;
    border:#d8d8d8 solid 1px;

}

.right-contact {
    margin-top:1.5em;
}

.right-contact span {
    display:block;
    font-size:0.9rem;
}

.right-contact a {
    text-decoration:none;
    color:black;
}

.right-contact .fa {
    color:#00963F;
}

.right-inner tbody {
    width:100%;
    display:table;
}


.inner-content  ul {
    list-style-type: none;
    padding:0;
}

.inner-content ul li {
    padding-left: 1.2em;
    padding-bottom:0.4em;
    padding-top:0.4em;
}

.inner-content ul li:before {
  content: "\f046"; /* FontAwesome char code inside the '' */
  font-family: FontAwesome; /* FontAwesome or whatever */
  display: inline-block;
  width: 1.2em; /* same as padding-left set on li */
  margin-left: -1.2em; /* same as padding-left set on li */
}

.kontakt .c-info span, .kontakt .opentime span {
    display:block;
}

.fa-map-marker:before {
    font-size:3rem;
}

.kontakt .location {
    margin-top:0.5em;
}

.kontakt .c-info a, .kontakt .opentime a {
    text-decoration:none;
}
    
.kontakt .opentime .openmoreinfo a {
    text-decoration:underline;
}
/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer span {
    display:block;
}

footer a {
    color: white;
    text-decoration:none;
}

footer a:hover {
    color:#F28D1A;
}

.contact-info {
    border-top:#313131 solid 1px;
    margin-top:0.5em;
}

.socialamedia a {
    font-size:2.0rem;
    padding-right:0.5em;
}

footer h4 {
    font-size:1.8rem;
    color:white;
}

footer td, footer th {
    border-bottom:#313131 solid 1px;
    padding:0.5em;
}

.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}



/* other
---------------------------------------------------------*/

.boltform label {
    display: none;
}

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button, input[type=reset], input[type=submit], button[type=submit], input[type=submit]{
    margin:0;
    background:#00963F;
    border:#00963F solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}
button[type=submit]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}


a {
    color:#17347A;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#000;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-family: OpenSans;
    font-weight: 400;
    color:#282d32;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

.container {
    max-width: 1280px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    color:#0E1F46;
    margin-top:0;
    margin-bottom:0.3em;
}

h1 {
      font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
  font-size: calc(1 * var(--scale-factor)rem);

}

h3 {
      font-size: 1.2rem;
    
}
/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

.headernamewrapper {
    margin-top: 2.5em;
}
/*
.bookcheckbox-row {
    display: none;
} */
.listacheckbox-row label, .bookcheckbox-row label{
    display: inline-block;
    font-weight: bold;
}

.lista-form {
    padding-bottom: 2em;
}



/* Example media queries*/
@media (max-width: 400px) {}

@media (min-width: 1300px) {
    .menu .dropdown ul {
        left: 0;
    }
}


@media (max-width: 550px) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:70vw;
    }
    header {
        min-height:53px;
    }
    .slick-track {
        height:100vw;
    }
}

@media (max-width: 750px) and (min-width: 551px ) {
    .zoombox, .zoomkatboxar, .zoomkatboxar .zoombox {
        height:40vw;
    }
}

@media (max-width: 800px) {
    .btnDown {
        bottom:5vh;
    }
    .bildspel_titel .container {
        font-size:1.1em;
    }
    .logo img {
        max-width:100px;
    }
    #menu-icon {
        position:absolute;
        top:12px;
        right:10px;
    }
    .zoombox h1, .zoombox h2 {
        font-size:1.7rem;
    }
    .leftmenu{
        -webkit-box-ordinal-group:3;
        -ms-flex-order:2;
        order:2
    }
    p {
        margin-top:0;
    }
    .headernamewrapper {
        margin-top: 1em;
    }
    
}
@media (max-width:var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#fff !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .menu li a {
        color: black;
    }
    .top-img {
        height:20vh;
    }
}

@media (min-width:var(--breakpoint-menu)) {
    /*.menu .dropdown ul {
        display:none !important;
    } */
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    .logo img {
        max-width:80px;
    }
    .headername {
        font-size: 0.8em;
    }
    .headernamewrapper {
        margin-top: 1.5em;
    }
    .listacheckbox-row {
        padding-bottom: 1em;
    }
    .listacheckbox-row label {
        display: inline;
    }
    .right-inner {
        padding:1em;
    }
}
/*--------------------------------------SUPPORT---------------------------------*/
.logTitle{position:relative; left:2em;}
